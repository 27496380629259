.exp-section {
  min-height: 100vh;

  padding: 50px 0;
}

.company-logo {
  max-width: 80px;
}

.experience-entry {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  opacity: 0;
}

.experience-details {
  flex: 1;
  padding-left: 20px;
}

.position {
  font-size: 1.2rem;
  font-weight: bold;
}

.duration,
.location {
  font-size: 1rem;
  margin-top: 5px;
}

/* Animations */
.animated {
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.experience-entry.animated {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-delay: calc(var(--index) * 0.2s);
  animation-timing-function: ease-out;
}

/* Hover Effect */
.experience-entry:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}
