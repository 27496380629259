.navbar {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.navbar-brand {
  font-weight: bold;
  text-decoration: none;
}

.navbar-toggler {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  display: none;
}

.navbar-toggler span {
  display: block;
  width: 25px;
  height: 2px;
  margin-bottom: 5px;
}

.navbar-links {
  display: flex;
}

.navbar-links.active {
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 10px 0;
  box-shadow: 0px 8px 16px;
  z-index: 1000;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-item {
  margin-left: 20px;
}

.nav-link {
  text-decoration: none;
  transition: color 0.3s ease;
  color: inherit; /* Ensure that the color is inherited from the parent */
}

.nav-link:hover {
  color: #fdc500;
}

.nav-link.active {
  transition: none;
}

@media (max-width: 768px) {
  .navbar-toggler {
    display: block;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links.active {
    display: flex;
    background-color: whitesmoke; /* Background color for active menu */
    flex-direction: column; /* Change flex direction to column */
    align-items: center; /* Center align items */
  }

  .navbar-nav {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0; /* Remove default padding */
  }

  .nav-item {
    margin: 10px 0;
  }

  .theme-button {
    margin-top: 10px; /* Adjust top margin */
  }

  /* Styling for dark mode */
  .dark .navbar-links.active {
    background-color: black; /* Dark mode background color for active menu */
  }
}



@media (min-width: 769px) {
  .navbar-links {
    display: flex !important;
    position: relative !important;
    flex-direction: row !important;
    background-color: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
  }

  .navbar-links.active {
    display: none !important;
  }

  .navbar-nav {
    flex-direction: row !important;
    justify-content: flex-end !important;
    
  }

  .nav-item {
    margin: 0 15px;
  }

  .navbar-toggler {
    display: none;
  }
}

.navbar.sticky {
  position: fixed;
  top: -10px;
  width: 100%;
  height: 50px;
}

.theme-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.theme-button:hover {
  transform: scale(1.1); /* Increase size on hover */
}

.theme-icon {
  font-size: 20px;
  transition: color 0.3s ease;
}

.light .theme-icon {
  color: black; /* Icon color for light theme */
}

.dark .theme-icon {
  color: #fff; /* Icon color for dark theme */
}

.dark .navbar {
  background-color: black; /* Background color for dark theme */
  color: #fff; /* Text color for dark theme */
}

.dark .navbar a.nav-link {
  color: #fff; /* Text color for dark theme navbar links */
}

.navbar.sticky.dark {
  background-color: black; /* Background color for dark theme when sticky */
  color: #fff; /* Text color for dark theme when sticky */
}






.navbar-toggler span:nth-child(2) {
  margin-top: 5px; /* Adjust spacing between lines */
}

.navbar-toggler span:last-child {
  margin-bottom: 0; /* Ensure proper spacing between lines */
}

/* Styling for light theme */


.light .navbar-toggler span {
  background-color: black; /* Color for light theme */
}

/* Styling for dark theme */
.dark .navbar-toggler span {
  background-color: #fff; /* Color for dark theme */
}
