.contact-section {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 
  
  form {
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .form-label {
    font-size: 1.2rem;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  textarea {
    height: 150px;
    resize: none;
  }
  
  .btn {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .thank-you-message {
    text-align: center;
    font-size: 1.2rem;
    color: #007bff;
  }
  