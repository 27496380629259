.about-section {
  min-height: 100vh;
  padding: 50px 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.section-heading {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem;
}

.about-content {
  line-height: 1.6;
}

.about-content p {
  margin-bottom: 20px;
}

.project-list,
.expertise-list {
  list-style: none;
  padding-left: 20px;
}

.project-list li,
.expertise-list li {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.project-list li::before,
.expertise-list li::before {
  content: '•';
  color: #007bff;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.about-content strong {
  font-weight: bold;
}
