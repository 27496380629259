/* Blogs.css */
.blogs h2{
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  /* color: #191528; */

 
}


.blog-grid {
    display: grid;
    gap: 1rem;
  }
  
  .blog-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc; /* Add border to each card */
    border-radius: 8px; /* Optional: Add border radius for rounded corners */
  }
  
  .blog-image {
    max-width: 100%;
    height: auto;
    border-bottom: 1px solid #ccc; /* Add border at the bottom of the image */
  }
  
  .blog-content {
    padding: 1rem;
  }
  
  .blog-content h3 {
    margin-top: 0;
  }
  
  .blog-content p {
    margin-top: 0.5rem;
  }
  
  .blog-content a {
    text-decoration: none;
    color: #fff;
  }
  
  .blog-content a:hover {
    text-decoration: underline;
  }
  
  /* Media queries */
  @media screen and (min-width: 768px) {
    .blog-grid {
      grid-template-columns: repeat(2, 1fr); /* Two columns on large screens */
    }
  }
/* Blogs.css */

.read-more {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #191528;
  color: #fff;
  text-decoration: none; /* Remove underline */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.read-more:hover {
  background-color: #fdc500;
  text-decoration: none !important; 
  color:  #fff;
}