.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .not-found-image {
    max-width: 100%;
    height: auto;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  
  .not-found-text {
    margin-top: 5px;
    font-size: 1.5rem;
    text-align: center;
  }
  